.hover-5::after {
  content: '';
  width: 100%;
  height: 10px;
  background: $xp-yellow;
  position: absolute;
  bottom: -10px;
  left: 0;
  display: block;
  transition: all 0.3s;
  z-index: 999;
}

.hover-5 .hover-overlay {
  background:  rgba(0, 0, 0, 0.2);
}

.hover-5-title {
  position: absolute;
  bottom: 1rem;
  left: 0;
  transition: all 0.3s;
  padding: 2rem 3rem;
  z-index: 99;
}

.hover-5-title span {
  transition: all 0.4s;
  opacity: 0;
  // color: $xp-yellow;
}

.hover-5:hover .hover-overlay {
  background: rgba(0, 0, 0, 0.7);
}

.hover-5:hover .hover-5-title {
  bottom: 0;
}

.hover-5:hover .hover-5-title span {
  opacity: 1;
}

.hover-5:hover::after {
  bottom: 0;
}