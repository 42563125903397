// Markets flash cards
.hover {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
  color: $xp-yellow;
}

.hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hover img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hover-content {
  position: relative;
  z-index: 99;
}

.hover-1 img {
  width: 105%;
  position: absolute;
  top: 0;
  left: -5%;
  transition: all 0.3s;
}

.hover-1-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.4s;
}

.hover-1 .hover-overlay {
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
}

.hover-1-description {
  transform: translateY(0.5rem);
  transition: all 0.4s;
  opacity: 0;
}

.hover-1:hover .hover-1-content {
  bottom: 2rem;
  // color: $xp-yellow;
}

.hover-1:hover .hover-1-description {
  opacity: 1;
  transform: none;
}

.hover-1:hover img {
  left: 0;
}

.hover-1:hover .hover-overlay {
  opacity: 1;
  
}

.hover-underlay {
  width: 60%;
  height: 20%;
  position: absolute;
  bottom: 20%;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
  background: rgba(0, 0, 0, 0.5);
}

.hover-1:hover .hover-1-title {
  background: transparent;
}

.hover-1-title{
  display: inline-block;
  background: rgba(0, 0, 0, 0.5);
}