@import url('https://fonts.googleapis.com/css?family=Montserrat:300,700&display=swap'); //Exo+2:300#|Krona+One
$font-family-sans-serif:      "Montserrat", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

// BOOTSTRAP VARIABLES OVERRIDES HERE

// main colors
$xp-dark-blue:     #2B3E50; //#2F3E4E;
$xp-mid-blue:      #4384AA;
$xp-light-blue:    #D6F4FF;
$xp-yellow:        #FFCC33; //#F8CD55;
$xp-light-gray:    #ADB6C1;

$theme-colors: (
  "primary": $xp-dark-blue,
  "secondary": $xp-mid-blue,
  "tertiary": $xp-light-blue,
  "danger": $xp-yellow
);

$dark: $xp-dark-blue;
$light: rgba(#fff, .7);
$spacer: 2.5rem; // affects navbar padding and navbar link spacing. 

// Body
$body-color: $xp-dark-blue;

// NavBar
$navbar-dark-color: rgba(#fff, .7);
$navbar-dark-active-color: rgba(#fff, .7);
$navbar-nav-link-padding-x: 2rem;

$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3),
    2rem: 2rem,
    dbl: ($spacer * 2)
  ),
  $spacers
);

// BOOTSTRAP VARIABLES OVERRIDES END

// @import "main";
@import "bootstrap/bootstrap";
@import "navbar";
@import "footer";
@import "masthead";
@import "markets";
@import "statement";
@import "team";
@import "xforge";
@import "contact";
@import "news";

.xp-bg-transparent {
  background-color: transparent;
}

.xp-bg-yellow {
  background-color: $xp-yellow;
}
.xp-bg-lightblue {
  background-color: $xp-light-blue;
}

body {
  background-color: rgb(12, 15, 19);
}

a.anchor {
  display: block;
  position: relative;
  top: -90px; // height of the fixed navbar.
  visibility: hidden;
}

.blue-radial-gradient {
  background: $xp-dark-blue;
  background: radial-gradient(circle, rgba($xp-dark-blue,1) 0%, rgba($xp-mid-blue,1) 50%);
}

.blue-linear-gradient {
  background: linear-gradient(90deg, rgba($xp-light-blue,1) 0%, rgba($xp-mid-blue,1) 50%);
}

.yellow-blue-diagonal-gradient {
  background: $xp-mid-blue;
  background: linear-gradient(135deg, rgba($xp-yellow,1) 0%, rgba($xp-mid-blue,1) 60%, rgba($xp-dark-blue,1) 100%);
}

.navbar-spacer {
  height: 90px;
}

// smooth scrolling
html {
	scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}