.navbar .nav-link {
  // font-family: "Krona One";
  font-weight: 300;
  font-size: 20px;
}

.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
    color: $xp-yellow;
}