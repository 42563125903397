.footer-dark {
  padding:50px 0;
  color:#f0f9ff;
  background-color: $xp-dark-blue;
}

.footer-dark h3 {
  margin-top:0;
  margin-bottom:12px;
  font-weight:bold;
  font-size:16px;
  color: rgba(#fff, 0.7);

  a {
    color: rgba(#fff, 0.7);
  }

  a:hover {
    color: $xp-yellow;
    opacity: 0.8;
    text-decoration: none;
  }
}

.footer-dark ul {
  padding:0;
  list-style:none;
  line-height:1.6;
  font-size:14px;
  margin-bottom:0;
}

.footer-dark ul a {
  color:inherit;
  text-decoration:none;
}

.footer-dark ul li {
  opacity:0.6;
}

// .footer-dark ul li:hover {
//   opacity:0.8;
// }

.footer-dark ul a:hover {
  opacity: 1;
  color: $xp-yellow;
}

@media (max-width:767px) {
  .footer-dark .item:not(.social) {
    text-align:center;
    padding-top:20px;
    padding-bottom:20px;
  }

  .footer-brand {
    text-align: center
  }
}

.footer-dark .item.text {
  margin-bottom:36px;
}

@media (max-width:767px) {
  .footer-dark .item.text {
    margin-bottom:0;
  }
}

.footer-dark .item.text p {
  opacity:0.6;
  margin-bottom:0;
}

.footer-dark .item.social {
  text-align:center;
}

@media (max-width:991px) {
  .footer-dark .item.social {
    text-align:center;
    margin-top:20px;
  }
}

.footer-dark .item.social {
  font-size:30px;
  // width:36px;
  // height:36px;
  // line-height:36px;
  // display:block;
  text-align:left;
  // border-radius:50%;
  // box-shadow:0 0 0 1px rgba(255,255,255,0.4);
  // margin:0 8px;
  // color:#fff;
  // opacity:0.75;
}

.footer-dark .item.social > a:hover {
  opacity:0.9;
}

.footer-dark .copyright {
  text-align:center;
  padding-top:24px;
  opacity:0.3;
  font-size:13px;
  margin-bottom:0;
}

.footer-container {
  padding: 0 4rem;
}

.footer-fixed-width {
  width: 110px;
}