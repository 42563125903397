.team {
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;  
  background-position: center;
}

.team.lazyloaded{
  background-image: url('/assets/img/yellow-mesh-bg.png');
}

.mugshot {
  max-height: 220px;
}