.masthead {
  height: 100vh;
  min-height: 500px;
  background-size: cover;
  background-position-x: 50%;
  background-position-y: 0%;
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: initial;
  background-image: url('/assets/img/header-992.jpeg'); 

  .mast-container {
    // padding: 0 4rem;
  }

  .mast-headline {
    // font-family: "Krona One";
    font-weight: 700;
    font-size: 36px;
  }
}

@media (max-width: 767px) {   
  .masthead{ 
    // background-image: url('/assets/img/header-576.jpeg'); 
    background-position: 70%;
    background-attachment: initial;
  }
  .mast-container { padding: 0 1rem; } 
}
@media (min-width: 768px) {      
  .masthead{ 
    // background-image: url('/assets/img/header-768.jpeg'); 
    background-attachment: initial;
  } 
} 
@media (min-width: 992px) {      
  .masthead{  
    // background-image: url('/assets/img/header-992.jpeg'); 
    background-attachment: initial;
  } 
}
@media (min-width: 1200px) {    
  .masthead{ 
    background-image: url('/assets/img/header-1200.jpeg'); 
    background-attachment: fixed;
  } 
}
@media (min-width: 1440px) {    
  .masthead{ 
    background-image: url('/assets/img/header-1440.jpeg'); 
    background-attachment: fixed;
  }  
}
@media (min-width: 1600px) {    
  .masthead{ 
    background-image: url('/assets/img/header-1600.jpeg'); 
    background-attachment: fixed;
  }  
}
@media (min-width: 1750px) {    
  .masthead{ 
    background-image: url('/assets/img/header-1920.jpeg'); 
    background-attachment: fixed;
  }  
}
@media (min-width: 2500px) {    
  .masthead{ 
    background-image: url('/assets/img/header-2560.jpeg'); 
    background-attachment: fixed;
  }  
}