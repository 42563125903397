.contact-bg {
  background: $xp-mid-blue;
}

.contact-map {
  min-height: 488px;
}

.post-recaptcha-mt {
  margin-top: 18px;
}

.contact2-bg {
  background-image: url("/assets/img/googlemap.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 600px;
}

.inherit-bg {
  background: inherit;
}

.glass-pane{
  position: relative;
  min-height: 300px;
  background: inherit;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgba(0,0,0,0.12);
}

.glass-pane:after{
  content: '';
  min-height: 420px;
  background: inherit; 
  position: absolute;
  left: -25px;
  right: 0;
  top: -25px;  
  bottom: 0;
  box-shadow: inset 0 0 0 250px rgba(0,0,0,0.1);
  filter: blur(10px);
}

.glass-pane-content{
  position: relative;
  z-index: 1;
}

.glass-input,
.glass-input:focus {
  background-color: rgba(0,0,0,.1);
  border: 1px solid rgba(0,0,0,0.15);
  box-shadow: inset 3px 3px 10px rgba(0, 0, 0, .15);
}

.glass-button {
  background-color: $xp-mid-blue;
  border: 1px solid $xp-yellow;
  color: $xp-yellow;
}

.btn-secondary:hover {
  color: $xp-yellow;
  background-color: $xp-mid-blue;
  border: 1px solid $xp-yellow;
}

// hijack the g-recaptcha response:
#g-recaptcha-response {
  display: block !important;
  position: absolute;
  margin: -78px 0 0 0 !important;
  width: 302px !important;
  height: 76px !important;
  z-index: -999999;
  opacity: 0;
}