$deck-card-height: 400px;
$deck-card-width: 280px;
$h-deck-card-overlap: 50px;
$v-deck-card-overlap: 100px;
$h-deck-min-width: calc(6*#{$deck-card-width} - 4*#{$h-deck-card-overlap} + 100px );

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(0) translateY(50%);
  }
	90% {
		transform: scale(1.05);
	}
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes fadeOutScale {
  0% {
    opacity: 1;
    transform: scale(1) translateY(0%);
  }
	10% {
		transform: scale(1.05);
	}
  100% {
    opacity: 1;
    transform: scale(0) translateY(50%);
  }
}

.deck-card {
  height: $deck-card-height;
  width: $deck-card-width;
  background-color: $xp-dark-blue;
  border-radius: 10px;
  box-shadow: -1rem 0 3rem #111;
  transition: 0.4s ease-out;
  position: relative;
  left: 0px;
}

.deck-card:not(:first-child) {
  margin-left: -$h-deck-card-overlap;
}

.deck-card:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.deck-card:hover ~ .deck-card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.deck-card-title {
  color: $xp-yellow;
}

.deck-card-bar {
  position: absolute;
  top: 25px;
  left: 20px;
  height: calc(#{$deck-card-height} - 50px);
  width: 5px;
  z-index: 1;
}

.deck-card-emptybar {
  background-color: #2e3033;
  width: 100%;
  height: 100%;
}

.deck-card-filledbar {
  position: absolute;
  bottom: 0px;
  z-index: 0;
  width: 100%;
  height: 0;
  background: rgb(0,154,217);
  background: linear-gradient(0deg, $xp-yellow 20%, $xp-mid-blue 80%);
  transition: 0.4s ease-out;
}

.deck-card:hover {
  .deck-card-filledbar {
  height: inherit;
  transition: 0.4s ease-out;
  }

  .deck-card-stroke {
    stroke-dashoffset: 0;
    transition: 0.6s ease-out;
  }

  .deck-card-mini-icon {
    
    path {
      animation: fadeInScale .6s ease-in-out backwards;
    }
    
    path:nth-child(2) {
      animation-delay: .2s;
    }
    
    path:nth-child(3) {
      animation-delay: .4s;
    }
  }

  .deck-card-icon {

    path {
      animation: fadeOutScale .6s ease-in-out forwards;
    }
    
    path:nth-child(2) {
      animation-delay: .2s;
    }
    
    path:nth-child(3) {
      animation-delay: .4s;
    }
  }

  .deck-card-content {
    opacity: 1;
    transition: .3s .6s ease-in-out;
  }
  
}

.deck-card-mini-icon {
  position: absolute;
  width: 10%;
  left: 45%;
  fill: $xp-yellow;
  stroke: $xp-yellow;

  path {
    animation: fadeOutScale .6s ease-in-out forwards;
  }
  
  path:nth-child(2) {
    animation-delay: .2s;
  }
  
  path:nth-child(3) {
    animation-delay: .4s;
  }
}

.deck-card-icon {
  position: absolute;
  width: 100%;
  bottom: 7.5%;
  max-height: 70%;
  fill: $xp-yellow;

  path {
    animation: fadeInScale .6s ease-in-out backwards;
  }
  
  path:nth-child(2) {
    animation-delay: .2s;
  }
  
  path:nth-child(3) {
    animation-delay: .4s;
  }
}

.deck-card-content {
  position: absolute;
  width: 100%;
  bottom: 0%;
  max-height: 77.5%;
  opacity: 0;
  transition: .3s ease-in-out;
  overflow: hidden;
}

/* change to vertical deck below the sm breakpoint */
@media (max-width: 575px) {
  .deck-card:not(:first-child) {
    margin-top: -$v-deck-card-overlap;
    margin-left: 0;
  }

  .deck-card:hover {
    transform: translateY(-$v-deck-card-overlap);
  }
}

