.statement {
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;  
  background-position-y: center;
  background-position-x: 80%;
}

.statement.lazyloaded {
  background-image: url('/assets/img/wired-X-bg-992.png');
}

.statement-headline {
  // font-family: "Krona One";
  font-weight: 700;
  font-size: 32px;
}

@media (min-width: 576px) {   
  .statement{ 
    background-position-x: 80%;
  }
}

@media (min-width: 768px) {    
  .statement{ 
    background-position-x: 80%;
  } 
}
@media (min-width: 1200px) {    
  .statement{ 
    background-position-x: 75%;
  }
  .statement.lazyloaded {
    background-image: url('/assets/img/wired-X-bg-1200.png');
  }
}
@media (min-width: 1440px) {    
  .statement{ 
    background-position-x: center;
  }
  .statement.lazyloaded {
    background-image: url('/assets/img/wired-X-bg-1440.png'); 
  } 
}
@media (min-width: 1600px) {    
  .statement{ 
    background-position-x: center;
  }
  .statement.lazyloaded {
    background-image: url('/assets/img/wired-X-bg-1600.png');     
  }
}
@media (min-width: 1750px) {    
  .statement{ 
    background-position-x: center;
  }
  .statement.lazyloaded {
    background-image: url('/assets/img/wired-X-bg-1920.png');     
  }
}
@media (min-width: 2500px) {    
  .statement{ 
    background-position-x: center;
  } 
  .statement.lazyloaded {
    background-image: url('/assets/img/wired-X-bg-2560.png');     
  }
}